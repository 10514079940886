
import React, { useEffect, useState, useMemo } from "react"
import classNames from "classnames"
import Layout from "../components/layout"
import SEO from "../components/seo"
import textStyles from "../styles/text.module.scss"
import queryString from "query-string"
import Spinner from "../components/spinner"
import { spinnerHarness } from '../styles/spinner.module.scss';
import axios from 'axios';
import { getGameDataBySKU } from "../utils/content"
import { buttonHarness, buttonHarnessNarrow, button, checkoutButton } from '../styles/button.module.scss'
import { Link } from "gatsby"
import LaptopBox from "../components/laptop-box"

const SuccessPage = ({ location }) => {
  const [transactionData, setTransactionData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const pollForData = () => {
    axios.get(`${process.env.GATSBY_API_ENDPOINT}/api/piing-party/get/${id}`)
      .then((response) => {
        // handle success
        setTransactionData(response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        setTimeout(pollForData, 500);
      })
  }

  const id = useMemo(() => {
    console.log('location:', location);
    return queryString.parse(location.search).id;
  }, [location]);

  const gameData = useMemo(() => {
    if (transactionData === null) return null;
    const data = getGameDataBySKU(transactionData.sku_id);
    return data;
  }, [transactionData]);

  useEffect(() => {
    if (id) pollForData();
  }, [id]);

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const launchBigScreen = (event) => {
    event.preventDefault();
    window.open(transactionData.big_screen_url, '_blank'); 
  }

  return (
    <Layout>
      <SEO title="Woohoo!" />
      {
        transactionData ?
          <>
            <div className={classNames(textStyles.title, textStyles.big)}>
              Let's get this <span className={textStyles.highlight}>party</span> started! 🥳
            </div>
            <div className={classNames(buttonHarness, buttonHarnessNarrow)}>
              <button onClick={toggleModal}target={'_blank'} rel='noopener noreferrer' className={classNames(button, checkoutButton)}>
                Launch your game!
              </button>
            </div>
            <div className={textStyles.body}>
              We've created a <Link to={'/quick-start'}>Quick Start Guide</Link> to help you get going.<br />Find out more in our <Link to={'/faq'}>FAQs</Link>.
            </div>
            <LaptopBox isOpen={showModal} handleClose={toggleModal} handleSubmit={launchBigScreen} />  
          </>
          : <div className={spinnerHarness}>
            <Spinner colour={'#fd176e'} />
          </div>
      }

    </Layout>
  )
}

export default SuccessPage