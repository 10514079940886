import React, { useRef, useState, useEffect, useMemo } from "react"
import { createPortal } from "react-dom";
import classNames from 'classnames'
import { buttonHarness, button, checkoutButton } from '../styles/button.module.scss'
import modalStyles from '../styles/modal.module.scss'
import { PiingPartyLogo } from "./piing-logo";
import { Link } from "gatsby";

const LaptopBox = ({ isOpen, handleClose, handleSubmit }) => {
  const handleOKButton = (event) => {
    event.preventDefault();
    handleSubmit(event);
  }

  return (
    <>
      {
        isOpen &&
        createPortal(
          <div className={modalStyles.modalContainer}>
            <div className={modalStyles.modalClickscreen} onClick={handleClose} />
              <div className={modalStyles.modalBox}>

                <button className={modalStyles.closeButton} onClick={handleClose}>&times;</button>
                <PiingPartyLogo small />
                
                <div className={modalStyles.modalBody}>
                  <p>We recommend you run the game in <a href='https://www.google.co.uk/chrome/' target='_blank' rel='noopener noreferrer'>Google Chrome</a> on a laptop or desktop PC.</p>
                  <p>Check out our <Link to={'/quick-start'} target={'_blank'} rel={'noopener noreferrer'}>Quick Start Guide</Link> for details.</p>
                </div>            

                <div className={buttonHarness}>
                  <button className={classNames(button, checkoutButton)} onClick={event => handleOKButton(event)}>I understand. Launch the game!</button>
                </div>

              </div>
          </div>
        , document.body)
      }
    </>
  ) 
}

export default LaptopBox